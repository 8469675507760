import React, { forwardRef, useMemo } from "react";
import { useModal } from "../../../../hooks/useModal";
import "../styles/song.scss";
import PlayIcon from "../../../../images/play.svg";
import Button from "../../../basic/Button";
import { useNextSong } from "../../../../hooks/useApi";
import { ADD_SONG_TO_RADIO_MODAL, VOTE_MODAL } from "../../../../constants";
import FavoriteButton from "../../../FavoriteButton";
import LoadableImage from "../../../basic/LoadableImage";
import colorFromString from "../../../../utils/colorFromString";
import cleanSongTitle from "../../../../utils/cleanSongTitle";
import DefaultSongCover from "../../../basic/DefaultSongCover";
import SongVotes from "./SongVotes";
// import DevPre from "../../../dev/DevPre";

const Song = forwardRef(
  ({ songid, songname, artist, cover, voters, radioid, nameseo }, ref) => {
    const { stackModal: stackModalVote } = useModal(VOTE_MODAL);
    const { stackModal: stackModalSelect } = useModal(ADD_SONG_TO_RADIO_MODAL);
    const { data: nextSongsData } = useNextSong(radioid);

    const [voted, votes, position, scid] = useMemo(() => {
      if (nextSongsData) {
        const index = nextSongsData.findIndex((song) => song.songid === songid);
        if (index > -1) {
          return [
            true,
            nextSongsData[index].votes,
            index,
            nextSongsData[index].scid,
          ];
        }
      }
      return [false, undefined, undefined];
    }, [songid, nextSongsData]);

    const handleOnVote = () => {
      stackModalVote({
        songid: songid,
        songname: songname,
        artist: artist,
        cover: cover,
        scid: scid,
        radioid: radioid,
        nameseo: nameseo,
      });
    };

    const handleSelectRadio = (radioid) => {
      stackModalSelect({
        songid: songid,
        songname: songname,
        artist: artist,
        cover: cover,
        scid: scid,
        radioid: radioid,
      });
    };

    const coverHttps = cover
      ? cover.replace("http://data", "https://data")
      : "";

    const cleanSongname = useMemo(
      () => cleanSongTitle(songname, artist),
      [songname, artist]
    );

    return (
      <button
        ref={ref}
        className={["song", voted ? "voted" : ""].join(" ")}
        title={songid}
        onClick={handleOnVote}
      >
        <div className="song-main-row">
          {voters ? (
            <div className="voter-container">
              {voters.map((voter) => (
                <Voter key={voter.userid} {...voter} />
              ))}
            </div>
          ) : null}
          {true || coverHttps !== "" ? (
            <LoadableImage
              src={coverHttps}
              alt={songname}
              className="cover"
              altComponent={<DefaultSongCover songid={songid} />}
            />
          ) : null}
          <div className="song-info">
            <div className="songname">{cleanSongname}</div>
            <div className="songartist">{artist}</div>
          </div>
          <FavoriteButton songid={songid} as="div" />
          {voted && <SongVotes votes={votes} />}
          {(radioid !== null && radioid !== undefined) || voted ? (
            <div className={["vote", voted ? "brand" : "play blue"].join(" ")}>
              {!voted && (
                <div className="icon-play">
                  <img src={PlayIcon} alt="play" />
                </div>
              )}
            </div>
          ) : (
            <div className={["icon-play"].join(" ")}>
              <img src={PlayIcon} alt="play" />
            </div>
          )}
        </div>
        {/* <DevPre>{songid}</DevPre> */}
      </button>
    );
  }
);

export default Song;

/**
 * Renders the voter user avatar with its name in the title
 * if the voter doesn't have an avatar it displays the name initials
 * @param {object} props - voter information
 * @param {string} props.name - voter name
 * @param {string} props.avatar - voter avatar url
 * @returns
 */
const Voter = ({ name, avatar }) => {
  const { textColor, main: mainColor } = useMemo(() => {
    return colorFromString(name);
  }, [name]);

  const initials = useMemo(() => {
    return name
      ?.split(" ")
      ?.map((word) => word[0])
      ?.join("") ?? "";
  }, [name]);

  return (
    <div
      className="voter"
      style={{
        backgroundColor: mainColor,
        color: textColor,
        backgroundImage: `url(${avatar})`,
      }}
      title={name}
    >
      {avatar === "" ? initials : ""}
    </div>
  );
};
