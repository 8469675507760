import React, { useMemo, useCallback, forwardRef } from "react";
import { BoxParent, Playlist } from "./Box";
import "../styles/boxes.scss";
import { T } from "../../../../hooks/useLanguage";

const BigBoxes = forwardRef(({ playlists, onSelectBox }, containerRef) => {
  const { containerStyle: boxesContainerStyle } = getGridSize(
    playlists,
    containerRef
  );

  return (
    <div className="big-boxes-wrapper">
      <div
        className={[
          "big-boxes",
          "playlists",
          `count-${playlists.length}`,
          playlists.length > 0 ? "with-boxes" : "",
        ].join(" ")}
      >
        <div className="boxes-container" style={boxesContainerStyle}>
          {playlists.map((playlist, index) => (
            <Playlist
              key={`playlist-${index}`}
              {...playlist}
              onSelectBox={onSelectBox}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default BigBoxes;

const BOX_HEIGHT = 120;
const BOX_WIDTH = 80;
const BOX_OFFSET_WIDTH = BOX_WIDTH + 16;
const BOX_OFFSET_HEIGHT = BOX_HEIGHT + 16;

function getGridSize(boxes, containerRef) {
  if (boxes) {
    let boxesHeightCount = boxes.length > 6 ? 3 : boxes.length > 3 ? 2 : 1;

    let containerWidth = "wide";

    if (containerRef.current !== undefined && containerRef.current !== null) {
      if (containerRef.current.offsetWidth < 500) {
        containerWidth = containerRef.current.offsetWidth;
        boxesHeightCount = boxes.length > 4 ? 3 : boxes.length > 3 ? 2 : 1;
      }
    }
    const boxesWidthCount = Math.ceil(boxes.length / boxesHeightCount);
    const boxesHeight = BOX_OFFSET_WIDTH * boxesHeightCount + 32;
    const boxesWidth = boxesWidthCount * BOX_OFFSET_HEIGHT + 32;

    const containerStyle = {
      // width: boxesWidth,
      // height: boxesHeight,
      // paddingRight:
      //   containerWidth === "wide"
      //     ? "1rem"
      //     : `${16 + containerWidth * 0.5 - boxesWidthCount * BOX_OFFSET_WIDTH}`,
      // paddingLeft:
      //   containerWidth === "wide"
      //     ? "1rem"
      //     : `${16 + containerWidth * 0.5 - boxesWidthCount * BOX_OFFSET_WIDTH}`,
    };

    return {
      height: boxesHeight,
      width: boxesWidth,
      containerWidth: containerWidth,
      containerStyle: containerStyle,
    };
  }

  return {
    height: 0,
    width: 0,
    containerWidth: 0,
    containerStyle: {},
  };
}
