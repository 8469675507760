import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "@reach/router";

import {
  useLocal,
  useLocals,
  useRadioidByNameseo,
  useSongs,
} from "../../../../hooks/useApi";

import { T } from "../../../../hooks/useLanguage";

import SearchInput from "../../../basic/SearchInput";
import StickyHeader from "../../../layout/StickyHeader";
import Song from "./Song";
import Loader from "../../../basic/Loader";
import BigBoxes from "./BigBoxes";
import DevPre from "../../../dev/DevPre";
import Button from "../../../basic/Button";

export default function SearchMusic({ nameseo }) {
  const boxesRef = useRef(null);
  const inputRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const urlSearch = new URL(location.href).searchParams.get("s") || "";
  const urlPlaylistid = new URL(location.href).searchParams.get("p") || "";

  const [search, setSearch] = useState(urlSearch);
  const [playlistid, setPlaylistid] = useState(urlPlaylistid);

  const { radioid } = useRadioidByNameseo(nameseo);

  const {
    isLoading: radioIsLoading,
    data: radioData,
    isError: radioIsError,
    error: radioError,
    radioid: currentRadioid,
  } = useLocal(radioid);

  const {
    songs,
    isLoading,
    isFetching,
    isError,
    isPreviousData,
    isRefetching,
    loadMore,
    total,
  } = useSongs(playlistid, search, radioid);

  useEffect(() => {
    // console.log("Input ref current", inputRef.current);
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus({
          preventScroll: false,
          focusVisible: true,
        });
      }, 300);
    }
  }, []);

  useEffect(() => {
    navigate(`/sonicbox/${nameseo}?s=${search}&p=${playlistid}`);
  }, [search, playlistid, navigate, nameseo]);

  const handleCancel = () => {
    setSearch("");
    setPlaylistid("");
    navigate(`/sonicbox/${nameseo}`);
  };

  const handleOnSelectBox = (selectedBox) => {
    // console.log("Selected box", selectedBox);
    setSearch("");
    setPlaylistid(selectedBox);
    navigate(`/sonicbox/${nameseo}?s=&p=${selectedBox}`);
  };

  const selectedBox = useMemo(() => {
    return radioData?.playlists?.find(
      (box) => parseInt(box.playlistid) === parseInt(playlistid)
    );
  }, [playlistid, radioData]);

  return (
    <>
      <StickyHeader
        style={{ flexDirection: "column", padding: "0.5em" }}
        startHeight={5}
        endHeight={5}
        animate={false}
      >
        <SearchInput
          ref={(input) => input?.focus()}
          value={search}
          onChange={setSearch}
          onCancel={handleCancel}
          autoFocus={true}
        />
        {selectedBox && (
          <div className="box-pill-container">
            <button
              type="button"
              onClick={() => handleOnSelectBox("")}
              className="box-pill"
            >
              {selectedBox.playlistname}
              <span className="icon">&times;</span>
            </button>
          </div>
        )}
        {songs && search !== "" ? (
          <div
            style={{ padding: "1em", textAlign: "center", fontSize: "0.75em" }}
          >
            {songs && songs.length > 0 ? (
              <T name={search}>sonicbox.resultsfor</T>
            ) : (
              <T name={search}>sonicbox.searchempty</T>
            )}
          </div>
        ) : null}
      </StickyHeader>
      {isLoading || isRefetching ? (
        <Loader />
      ) : isError ? (
        <div className="error">
          <T>sonicbox.searcherror</T>
        </div>
      ) : null}
      <ul className="songs-container">
        {selectedBox && <div style={{ padding: "0.3em", width: "100%" }} />}
        {search !== "" || playlistid !== "" ? (
          songs &&
          !isPreviousData &&
          songs.map((local, index) => (
            <Song
              key={index.toString()}
              {...local}
              onClick={(nameseo) => navigate(`/sonicbox/${nameseo}`)}
              label={<T>sonicbox.getin</T>}
              nameseo={nameseo}
            />
          ))
        ) : (
          <>
            <BigBoxes
              ref={boxesRef}
              playlists={
                radioData
                  ? radioData.playlists.sort((playlist) =>
                      playlist.playlistid === "737" ? -1 : 1
                    )
                  : []
              }
              onSelectBox={handleOnSelectBox}
            />
          </>
        )}
      </ul>
      <div className="view-more">
        {songs && total > songs.length && (
          <Button
            onClick={() => loadMore()}
            disabled={isPreviousData}
            className={isPreviousData || isFetching ? "loading" : null}
            style={{
              marginBottom: 30,
              color: isPreviousData || isFetching ? null : "white",
            }}
          >
            {isPreviousData || isFetching ? (
              <T>sonicbox.loading</T>
            ) : (
              <T>sonicbox.loadmore</T>
            )}
          </Button>
        )}
        {/* <div className="count-songs">{`${songs?.length} / ${total}`}</div> */}
      </div>
    </>
  );
}
