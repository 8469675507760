import React, {
  useRef,
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { I18n } from "i18n-js";
import { es } from "date-fns/locale";
import en from "date-fns/locale/en-US";
import axios from "axios";

const LANG_OPTIONS = [
  {
    label: "Esp",
    value: "es",
  },
  {
    label: "Eng",
    value: "en",
  },
];

const I18N_OPTIONS = {
  defaultLocale: "en",
};

const LanguageContext = createContext({
  i18n: { t: (key) => key, locale: null },
  locale: "",
  mayorLanguage: "",
  dateFnsLocale: en,
  setLocale: () => {},
  update: 0,
});

export const LanguageProvider = ({ children }) => {
  const i18n = useRef(new I18n(I18N_OPTIONS));
  const [locale, setLocale] = useState("");
  const [dateFnsLocale, setDateFnsLocale] = useState(en);
  const [translations, setTranslations] = useState({});
  const [update, setUpdate] = useState(0);
  const updating = useRef(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const unsubscribe = i18n.current.onChange(() => {
      setUpdate((_update) => _update + 1);
    });
    return unsubscribe;
  }, [i18n]);

  const getTranslation = useCallback(
    (code) => {
      if (typeof code === "string" && code !== "") {
        updating.current = true;
        axios
          .get(`/language/${code}.json?v=${process.env.REACT_APP_VERSION}`)
          .then((res) => {
            setTranslations({ ...translations, ...res.data });
            console.log("translation", update + 1, code, res.data);
            i18n.current.store(res.data, code);
            i18n.current.locale = code;
            i18n.current.enableFallback = false;
            setUpdate((_update) => _update + 1);
            setLocale(code);
            updating.current = false;
          });
      }
    },
    [translations, update]
  );

  useEffect(() => {
    if (!ready) {
      setReady(true);
      const storedLang = localStorage.getItem("lang");
      let userLang = storedLang;
      if (typeof storedLang !== "string") {
        userLang = navigator.language || navigator.userLanguage;
      }
      let mayorLanguage = userLang.split("-")[0];
      console.log("translate userLang", storedLang, i18n.current.locale);
      if (
        userLang === "es" ||
        userLang === "es-AR" ||
        userLang === "es-UY" ||
        userLang === "en"
      ) {
        if (typeof translations[userLang] === "undefined") {
          getTranslation(userLang);
        }
      } else {
        if (mayorLanguage !== "es") {
          mayorLanguage = "en";
        }
        if (typeof translations[mayorLanguage] === "undefined") {
          getTranslation(mayorLanguage);
        }
      }
    }
  }, [ready, translations, getTranslation, update]);

  useEffect(() => {
    localStorage.setItem("lang", locale);
    if (i18n.current.locale !== locale && !updating.current) {
      getTranslation(locale);
    }
  }, [locale, getTranslation]);

  const mayorLanguage = locale.split("-")[0];

  useEffect(() => {
    if (mayorLanguage === "es") {
      setDateFnsLocale(es);
    } else {
      setDateFnsLocale(en);
    }
  }, [locale, i18n.locale, mayorLanguage]);

  return (
    <LanguageContext.Provider
      value={{
        i18n: i18n.current,
        locale,
        mayorLanguage,
        dateFnsLocale,
        setLocale,
        update,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => useContext(LanguageContext);

export default useLanguage;

export const LangSwitcher = () => {
  const [expanded, setExpanded] = useState(false);
  const { mayorLanguage, setLocale } = useLanguage();

  const activeOption = LANG_OPTIONS.find(
    (option) => option.value === mayorLanguage
  );

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target.id !== "lang-switcher") {
        setExpanded(false);
      }
    });
  }, []);

  return (
    <div className={["lang-switcher", expanded ? "expanded" : ""].join(" ")}>
      <button
        className="active"
        onClick={() => setExpanded(!expanded)}
        id="lang-switcher"
      >
        {activeOption?.label}
      </button>
      {expanded ? (
        <div class="lang-switcher-options">
          {LANG_OPTIONS.map((lang) => (
            <button
              key={lang.value}
              className={mayorLanguage === lang.value ? "active" : ""}
              onClick={() => setLocale(lang.value)}
            >
              {lang.label}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export function T(props) {
  const children = props.children;
  const { i18n, locale, update } = useLanguage();

  const text = useMemo(() => {
    if (update > -1 && typeof locale === "string") {
      return i18n.t(children, props);
    }
    return "";
  }, [i18n, children, update, props, locale]);

  if (typeof text !== "string") return null;

  const lines = text.split("\n");
  return (
    <span className={`t-${locale} tu-${update}`} langkey={children}>
      {lines.map((line, index) => (
        <Line key={index} line={line} br={index < lines.length - 1} />
      ))}
    </span>
  );
}

const TAGS = [
  { strong: (children) => <strong>{children}</strong> },
  { small: (children) => <small>{children}</small> },
  { span: (children) => <span>{children}</span> },
  { em: (children) => <em>{children}</em> },
  { b: (children) => <b>{children}</b> },
  { i: (children) => <i>{children}</i> },
  { u: (children) => <u>{children}</u> },
  { p: (children) => <p>{children}</p> },
  { div: (children) => <div>{children}</div> },
  { h1: (children) => <h1>{children}</h1> },
  { h2: (children) => <h2>{children}</h2> },
];

function Line({ line, br = true }) {
  const tags = useMemo(() => {
    if (typeof line === "string") {
      let result = [{ Tag: null, string: line }];
      TAGS.forEach((tagtype) => {
        const regex = new RegExp(`<${tagtype}>(.*?)<\\/${tagtype}>`, "g");
        const tagMatches = line.match(regex);
        if (tagMatches) {
          result = [];
          let remainingLine = line;
          tagMatches.forEach((str) => {
            const parts = remainingLine.split(str);
            result.push({ Tag: null, string: parts[0] });
            result.push({
              Tag: ({ children }) => <strong>{children}</strong>,
              string: str.replace("</strong>", "").replace("<strong>", ""),
            });
            remainingLine = parts[1];
          });
          result.push({ Tag: null, string: remainingLine });
        }
      });
      return result;
    }
    return [];
  }, [line]);

  return (
    <>
      {tags.map((tag, index) =>
        tag.Tag ? <tag.Tag key={index}>{tag.string}</tag.Tag> : tag.string
      )}
      {br ? <br /> : null}
      <br />
    </>
  );
}
