import { useUser } from "../../../hooks/useApi";
import { T } from "../../../hooks/useLanguage";
import AccountHeader from "./components/AccountHeader";
import "./styles/credits.scss";
import QRScanner from "../../QRScanner";

const Scanner = () => {
  return (
    <>
      <AccountHeader
        back={"/credits"}
        title={<T count={0}>navbar.scanqrcode</T>}
      />
      <QRScanner />
    </>
  );
};

export default Scanner;
