import { useCallback, useEffect, useState } from "react";
import { useTerms } from "../../hooks/useApi";
import Loader from "../basic/Loader";
import Modal from "../layout/Modal";
import Button from "../basic/Button";

import "../../styles/terms.scss";

export default function TermsAndConditionsModal({
  nameseo,
  isPeeking,
  setIsPeeking,
}) {
  const { extraQuery, data, isLoading, acceptTerms } = useTerms(nameseo);
  const [ticked, setTicked] = useState(false);
  const [form, setForm] = useState(null);
  const [checked, setChecked] = useState([]);

  const handleAcceptTerms = () => {
    console.log("accepting terms");
    acceptTerms.mutate(form ? checked : ["terms"]);
  };

  const handleClosePeeking = () => {
    setIsPeeking(false);
  };

  const handleCheck = useCallback((event) => {
    console.log(event.target.name, event.target.checked);
    if (event.target.checked) {
      setChecked((checked) => [...checked, event.target.name]);
    } else {
      setChecked((checked) =>
        checked.filter((name) => name !== event.target.name)
      );
    }
  }, []);

  useEffect(() => {
    if (form) {
      let acceptAll = true;
      form.forEach(({ input }) => {
        if (input?.required) {
          if (!checked.includes(input.name)) {
            acceptAll = false;
          }
        }
      });
      setTicked(acceptAll);
    }
  }, [checked, form]);

  useEffect(() => {
    if (acceptTerms.isSuccess) {
      extraQuery.refetch();
    }
  }, [acceptTerms.isSuccess, extraQuery, extraQuery.refetch]);

  useEffect(() => {
    if (data?.text) {
      const dom = document.createElement("div");
      dom.innerHTML = data.text;
      const _form = dom.querySelector("form");
      console.log("has form?", _form);
      if (_form) {
        const formGroups = _form.querySelectorAll(".form-group");
        const components = [];
        formGroups.forEach((formGroup) => {
          const label = formGroup.querySelector("label");
          const input = formGroup.querySelector("input");
          const small = formGroup.querySelector("small");
          const link = label.querySelector("a");
          components.push({
            label,
            input,
            small,
            link,
          });
        });
        setForm(components);
      } else {
        setForm(null);
      }
    }
  }, [data, handleCheck]);

  if (isLoading) return <Loader />;
  if (data) {
    return (
      <Modal disableClose={true} className="terms-modal">
        {form ? (
          <div
            className="terms-container"
            style={{
              height:
                Math.max(20, Math.min(60, data.text.length * 0.08)) + "vh",
            }}
          >
            {form.map((formGroup, index) => (
              <FormGroup
                key={index}
                onChange={handleCheck}
                {...formGroup}
                isPeeking={isPeeking}
              />
            ))}
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: data.text }}
            className="terms-container"
            style={{
              height:
                Math.max(20, Math.min(60, data.text.length * 0.08)) + "vh",
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {isPeeking ? (
            <Button
              onClick={handleClosePeeking}
              className="success"
              style={{ marginLeft: 10 }}
            >
              Cerrar
            </Button>
          ) : (
            <>
              {!form && (
                <input
                  type="checkbox"
                  checked={ticked}
                  onChange={(event) => setTicked((ticked) => !ticked)}
                  style={{ width: 30, height: 30 }}
                  disabled={!!form}
                />
              )}
              <Button
                onClick={handleAcceptTerms}
                className="success"
                disabled={!ticked}
                style={{ marginLeft: 10 }}
              >
                {acceptTerms?.isLoading ? <Loader /> : "Autorizar"}
              </Button>
            </>
          )}
        </div>
      </Modal>
    );
  }
  return null;
}

function FormGroup({ label, input, small, link, onChange, isPeeking }) {
  let labelText = label?.textContent;
  const inputName = input?.name;
  const smallText = small?.textContent;
  const linkHref = link?.href;
  const linkText = link?.textContent;
  const required = !!input?.required;

  if (link && linkHref && linkText) {
    labelText = labelText.replace(linkText, "");
  }

  if (isPeeking) {
    return (
      link && (
        <div
          className="form-group"
          style={{ display: "flex", marginBottom: 10 }}
        >
          <a
            href={linkHref}
            target="_blank"
            rel="noreferrer"
            style={{ whiteSpace: "pre" }}
          >
            {linkText}
          </a>
        </div>
      )
    );
  }

  return (
    <div className="form-group" style={{ display: "flex", marginBottom: 10 }}>
      <input
        type="checkbox"
        onChange={onChange}
        name={inputName}
        required={required}
        style={{
          width: 20,
          height: 20,
          flexGrow: 0,
          flexShrink: 0,
          marginRight: 20,
        }}
      />
      <div>
        <label>
          {labelText}
          {link && (
            <a
              href={linkHref}
              target="_blank"
              rel="noreferrer"
              style={{ whiteSpace: "pre" }}
            >
              {linkText}
            </a>
          )}
        </label>{" "}
        <small style={{ opacity: 0.5 }}>{smallText}</small>
      </div>
    </div>
  );
}
