import { useNavigate } from "@reach/router";
import { useMemo, useEffect, useState, useRef } from "react";
import { usePlaylist, useSingleSong } from "../../../../hooks/useApi";
import { T } from "../../../../hooks/useLanguage";
import "../styles/artists.scss";
import LoadableImage from "../../../basic/LoadableImage";
import DefaultArtistCover from "../../../basic/DefaultArtisCover";

export default function Artists({ radio }) {
  const lastUpdateRef = useRef(0);
  const [randomSongs, setRandomSongs] = useState([]);
  const navigate = useNavigate();
  const { isLoading, song } = useSingleSong(
    radio?.radioid,
    radio?.playing,
    radio?.playingsongid
  );

  const playlistid = song?.playlistid;

  const { songs } = usePlaylist(radio?.radioid, playlistid, true, 0, 400);
  const handleSelectArtist = (artist) => {
    navigate(`/sonicbox/${radio.nameseo}?s=${artist}`);
  };

  // update random song only if there are new songs
  useEffect(() => {
    if (songs?.length > 16) {
      const newSongs = [];
      while (newSongs.length < 16) {
        const randomIndex = Math.floor(Math.random() * songs.length);
        if (!newSongs.includes(songs[randomIndex])) {
          newSongs.push(songs[randomIndex]);
        }
      }
      const now = new Date().getTime();
      if (now - lastUpdateRef.current > 60 * 1000) {
        lastUpdateRef.current = now;
        setRandomSongs(newSongs);
      }
    }
  }, [songs]);

  const splittedArtists = useMemo(() => {
    // console.log("songs songs", randomSongs);
    if (!Array.isArray(randomSongs)) {
      return [];
    }
    const result = randomSongs.map((song) => {
      const splitted = song.artist
        .split(", ")
        .join(" | ")
        .split("; ")
        .join(" | ")
        .split(" - ")
        .join(" | ")
        .split(" | ");
      // return splittedByCommas.map((name) => ({
      //   ...song,
      //   artist: name,
      //   splitted: splittedByCommas.length > 1,
      // }));
      return {
        ...song,
        artist: splitted[0],
        splitted: splitted.length > 1,
      };
    });
    const artists = result.flat();
    return artists;
  }, [randomSongs]);

  const uniqueArtists = useMemo(() => {
    const uniqueArtists = [];
    splittedArtists.forEach((artist) => {
      const found = uniqueArtists.findIndex((a) => a.artist === artist.artist);
      if (found < 0) {
        uniqueArtists.push(artist);
      } else {
        const existingArtist = uniqueArtists[found];
        // console.log(
        //   "found",
        //   artist.artist,
        //   ":",
        //   uniqueArtists[found].cover,
        //   "|",
        //   artist.cover,
        //   "splitted",
        //   existingArtist.splitted,
        //   artist.splitted
        // );

        if (existingArtist.splitted && !artist.splitted) {
          uniqueArtists[found] = artist;
        } else if (existingArtist.cover === "" && artist.cover !== "") {
          uniqueArtists[found] = artist;
        }
      }
    });
    // console.log(
    //   "uniqueArtists",
    //   uniqueArtists.map((a) => a.artist)
    // );
    return uniqueArtists;
  }, [splittedArtists]);

  const randomizeArtists = useMemo(
    () => uniqueArtists.sort(() => 0.5 - Math.random()),
    [uniqueArtists]
  );

  const firstArtists = useMemo(() => {
    if (!Array.isArray(randomizeArtists)) {
      return [];
    }
    if (randomizeArtists.length < 15) {
      return randomizeArtists;
    }
    return randomizeArtists.slice(0, 15);
  }, [randomizeArtists]);

  return (
    <div className="artists">
      <h2>
        <T>sonicbox.artists</T>
      </h2>
      <div className="artists-container">
        {firstArtists.map((artist) => (
          <button
            type="button"
            key={artist.songid}
            className="artist"
            onClick={() => handleSelectArtist(artist.artist)}
          >
            <LoadableImage
              src={artist.cover}
              alt={artist.artist}
              className="cover"
              altComponent={<DefaultArtistCover songid={artist.songid} />}
            />
            <span className="name">{artist.artist}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
