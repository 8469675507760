import React from "react";
import {
  VOTE_MODAL,
  CODE_MODAL,
  ADD_CREDITS_MODAL,
  ADD_CREDITS_SUCCESS_MODAL,
  VOTED_SUCCESS_MODAL,
  ADD_SONG_TO_RADIO_MODAL,
  DOWNLOAD_APP_MODAL,
  DOWNLOAD_APP_REDEEM_MODAL,
  TERMS_AND_CONDITIONS_MODAL,
  HELP_MODAL,
  SELECT_BRANCH_MODAL,
} from "../../constants";
import CodeModal from "./CodeModal";
import VoteModal from "./VoteModal";
import VotedSuccessModal from "./VotedSuccessModal";
import AddCreditsModal from "./AddCreditsModal";
import AddCreditSuccessModal from "./AddCreditSuccessModal";
import AddSongToRadioModal from "./AddSongToRadioModal";
import DownloadAppModal from "./DownloadAppModal";
import DownloadAppRedeemModal from "./DownloadAppRedeemModal";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import HelpModal from "./HelpModal";
import SelectBranchModal from "./SelectBranchModal";

const modals = {};
modals[VOTE_MODAL] = (params) => <VoteModal {...params} />;
modals[CODE_MODAL] = (params) => <CodeModal {...params} />;
modals[ADD_CREDITS_MODAL] = (params) => <AddCreditsModal {...params} />;
modals[ADD_CREDITS_SUCCESS_MODAL] = (params) => (
  <AddCreditSuccessModal {...params} />
);
modals[VOTED_SUCCESS_MODAL] = (params) => <VotedSuccessModal {...params} />;
modals[ADD_SONG_TO_RADIO_MODAL] = (params) => (
  <AddSongToRadioModal {...params} />
);
modals[DOWNLOAD_APP_MODAL] = (params) => <DownloadAppModal {...params} />;
modals[DOWNLOAD_APP_REDEEM_MODAL] = (params) => (
  <DownloadAppRedeemModal {...params} />
);
modals[TERMS_AND_CONDITIONS_MODAL] = (params) => (
  <TermsAndConditionsModal {...params} />
);
modals[HELP_MODAL] = (params) => <HelpModal {...params} />;

modals[SELECT_BRANCH_MODAL] = (params) => <SelectBranchModal {...params} />;

export default modals;
