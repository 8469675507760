import React, { useMemo } from "react";
import { useUser } from "../../../../hooks/useApi";
import Loader from "../../../basic/Loader";
import DevPre from "../../../dev/DevPre";
import OnoffSVG from "../../../../images/onoff.svg";
import Button from "../../../basic/Button";
import "../styles/profile.scss";
import LoadableImage from "../../../basic/LoadableImage";

const ProfileButton = () => {
  const { userQuery, user, logged, logout } = useUser();

  const { name, avatar } =
    user !== null
      ? user
      : {
          name: "",
          email: "",
          avatar: null,
        };

  const firstLetters = useMemo(() => {
    const split = name.split(" ");
    return split.reduce((prev, current) => `${prev}${current[0]}`, "");
  }, [name]);

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="profile-button">
      {userQuery.isLoading ? (
        <Loader />
      ) : userQuery.isError ? (
        <p style={{ color: "red" }}>{userQuery.error}</p>
      ) : logged ? (
        <>
          <div className="avatar-container">
            {avatar ? (
              <LoadableImage
                src={avatar}
                alt={name}
                altComponent={<span>{firstLetters}</span>}
              />
            ) : (
              <span>{firstLetters}</span>
            )}
          </div>
          <div className="profile-info">
            <div className="name">{user.name}</div>
            <div className="email">{user.email}</div>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default ProfileButton;
