import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import InView from "react-intersection-observer";

const ScrollContext = createContext();

export const useScrollObserver = () => {
  const { inView, ref, entry, addScrollCallback, removeScrollCallback } =
    useContext(ScrollContext);
  return {
    inView,
    ref,
    entry,
    addScrollCallback,
    removeScrollCallback,
  };
};

export const ScrollProvider = (props) => {
  const [callbacks, setCallbacks] = useState([]);

  const handleOnScroll = useCallback(
    (e) => {
      callbacks.forEach(
        (callback) => callback && callback(document.documentElement.scrollTop)
      );
    },
    [callbacks]
  );

  const addScrollCallback = useCallback((callback) => {
    setCallbacks((callbacks) => [...callbacks, callback]);
    return callbacks.length;
  }, [callbacks.length]);

  const removeScrollCallback = useCallback((index) => {
    setCallbacks((callbacks) => {
      callbacks[index] = null;
      return callbacks;
    });
  }, []);

  useEffect(() => {
    document.onscroll = handleOnScroll;
  }, [handleOnScroll]);

  return (
    <div className={props.className}>
      <InView {...props} as="div" initialInView={true}>
        {({ inView, ref, entry }) => (
          <ScrollContext.Provider
            value={{
              inView,
              ref,
              entry,
              addScrollCallback,
              removeScrollCallback,
            }}
          >
            {props.children}
          </ScrollContext.Provider>
        )}
      </InView>
    </div>
  );
};
