import React, { useState, forwardRef } from "react";

import LaggedInput from "./LaggedInput";
import SearchPNG from "../../images/icon-search-brand.png";
import { T } from "../../hooks/useLanguage";

import "../../styles/search-input.scss";

function SearchInput({ value, onChange, onCancel, autoFocus }, ref) {
  return (
    <div className="search-input">
      <LaggedInput
        ref={ref}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
      />
      <img src={SearchPNG} alt="Search" className="icon" />
      <button onClick={onCancel}>
        <T>{value ? "delete" : "cancel"}</T>
      </button>
    </div>
  );
}

export default forwardRef(SearchInput);
