import { useEffect, StyleSheet, useRef } from "react";
import { useScrollObserver } from "../../hooks/useScrollObserver";
import interpolate from "../../utils/interpolate";

export default function StickyHeader({
  children,
  startScroll = 20,
  endScroll = 200,
  startHeight,
  endHeight,
  style = {},
  callback = null,
  className = "",
  animate = true,
}) {
  const containerRef = useRef(null);
  const isReady = useRef(false);
  const { addScrollCallback, removeScrollCallback } = useScrollObserver();

  useEffect(() => {
    if (animate) {
      if (!isReady.current) {
        containerRef.current.style.height = `${startHeight}em`;
        const index = addScrollCallback((scrollY) => {
          if (containerRef.current) {
            const height = interpolate(
              scrollY,
              [startScroll, endScroll],
              [startHeight, endHeight]
            );
            console.log("scrollY", scrollY, height);
            containerRef.current.style.height = `${height}em`;
          }
        });
        isReady.current = true;

        const index2 = addScrollCallback((scrollY) => {
          if (callback !== null) return callback(scrollY, containerRef);
        });

        return () => {
          removeScrollCallback(index);
          removeScrollCallback(index2);
          isReady.current = false;
        };
      }
    }
  }, [
    startScroll,
    endScroll,
    endHeight,
    startHeight,
    addScrollCallback,
    removeScrollCallback,
    callback,
    animate,
  ]);

  return (
    <>
      <div
        ref={containerRef}
        style={{ ...styles.container, ...style }}
        className={["sticky-header", className].join(" ")}
      >
        {children}
      </div>
      <div style={{ height: `${startHeight}em` }} />
    </>
  );
}

const styles = {
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    display: "flex",
    // background: "#f003",
  },
};
