import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "../../styles/input.scss";
import Button from "./Button";

const keyboardLayout = {
  default: [
    "{exit} ` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{placeholder} q w e r t y u i o p [ ] \\",
    "{lock} a s d f g h j k l ; ' {enter}",
    "{shift} z x c v b n m , . / {shift}",
    "{space}",
  ],
  shift: [
    "{exit} ~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{placeholder} Q W E R T Y U I O P { } |",
    '{lock} A S D F G H J K L : " {enter}',
    "{shift} Z X C V B N M &lt; &gt; ? {shift}",
    "{space}",
  ],
};
const keyboardDisplay = {
  "{bksp}": "Borrar",
  "{enter}": "Buscar",
  "{space}": "Espacio",
  "{shift}": "Mayús.",
  "{lock}": "Bloq.",
  "{exit}": "Salir",
  "{placeholder}": " ",
};

const InputText = (props, ref) => {
  const inputRef = useRef();
  const keyboardRef = useRef();
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [keyboardLayoutName, setKeyboardLayoutName] = useState("default");
  const keyboardLayoutNameRef = useRef("default");

  const toggleShift = useCallback(() => {
    // console.log("keyboard", keyboardLayoutName, keyboardLayoutNameRef.current);
    setKeyboardLayoutName(
      keyboardLayoutNameRef.current === "default" ? "shift" : "default"
    );
  }, [keyboardLayoutName, keyboardLayoutNameRef]);

  const shiftTimeout = () => setTimeout(toggleShift, 2000);

  const handleOnChange = (event) => {
    props.onChange(event.target.value, event.target.name);
  };

  const handleKeyboardChange = (value) => {
    // console.log("keyboardChange", value);
    props.onChange(value, inputRef.current.name);
  };

  const handleKeyboardPress = (key) => {
    // console.log("keyPress", key);
    switch (key) {
      case "{exit}":
        props.onChange("", inputRef.current.name);
        setShowKeyboard(false);
        inputRef.current.blur();
        setKeyboardLayoutName("default");
        break;
      case "{bksp}":
        // console.log("bksp", inputRef.current.value);
        if (inputRef.current.value.length === 1) {
          setShowKeyboard(false);
          inputRef.current.blur();
          setKeyboardLayoutName("default");
        }
        break;
      case "{enter}":
        setShowKeyboard(false);
        inputRef.current.blur();
        setKeyboardLayoutName("default");
        break;
      case "{lock}":
        toggleShift();
        break;
      case "{shift}":
        toggleShift();
        shiftTimeout();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // console.log("keyboard now is ", keyboardLayoutName);
    keyboardLayoutNameRef.current = keyboardLayoutName;
  }, [keyboardLayoutName]);

  useEffect(() => {
    if (keyboardRef && keyboardRef.current !== undefined) {
      // console.log("useEffect keyboard", props.value);
      keyboardRef.current.setInput(props.value);
    }
  }, [props.value, showKeyboard]);

  useEffect(() => {
    document.addEventListener("click", function (event) {
      if (ref !== null && ref.current !== null && ref.current !== undefined) {
        const isClickInsideElement = ref.current.contains(event.target);
        if (!isClickInsideElement) {
          setShowKeyboard(false);
        }
      }
    });
  }, [ref]);

  const inputId = useMemo(() => {
    return `input-${(1000000 * Math.random()).toString(16)}`;
  }, []);

  const filteredProps = { ...props };
  if (filteredProps.hasOwnProperty("containerClassName")) {
    delete filteredProps.containerClassName;
  }
  if (filteredProps.hasOwnProperty("deleteBtn")) {
    delete filteredProps.deleteBtn;
  }
  if (filteredProps.hasOwnProperty("withKeyboard")) {
    delete filteredProps.withKeyboard;
  }
  return (
    <div
      ref={ref}
      className={[
        "input-container",
        props.containerClassName ? props.containerClassName : "",
      ].join(" ")}
    >
      {props.label ? <label htmlFor={inputId}>{props.label}</label> : null}
      <input
        id={inputId}
        ref={inputRef}
        {...filteredProps}
        onChange={handleOnChange}
        onFocus={() => setShowKeyboard(!isMobile)}
      />
      {props.withKeyboard && showKeyboard ? (
        <Keyboard
          keyboardRef={(r) => (keyboardRef.current = r)}
          onChange={handleKeyboardChange}
          onKeyPress={handleKeyboardPress}
          theme="hg-theme-default simple-keyboard"
          layout={keyboardLayout}
          display={{
            ...keyboardDisplay,
            "{bksp}": filteredProps.value.length > 1 ? "Borrar" : "Salir",
          }}
          layoutName={keyboardLayoutName}
        />
      ) : null}
      {props.deleteBtn && props.value !== "" ? (
        <Button
          className="clear-input-button"
          as="text"
          onClick={() => {
            props.onChange("");
          }}
        >
          &times;
        </Button>
      ) : null}
    </div>
  );
};

export default forwardRef(InputText);
