import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "@reach/router";

import { useLocals } from "../../../../hooks/useApi";
import useGeoLocation from "../../../../hooks/useGeoLocation";
import { T } from "../../../../hooks/useLanguage";

import SingleLocal from "./SingleLocal";
import SearchInput from "../../../basic/SearchInput";
import StickyHeader from "../../../layout/StickyHeader";

export default function SearchLocales() {
  const inputRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const urlSearch = new URL(location.href).searchParams.get("s");

  const [search, setSearch] = useState(urlSearch);

  const {
    isLoading: loadingLocation,
    error: errorLocation,
    lat,
    lng,
  } = useGeoLocation();

  const { data } = useLocals(lat, lng, search);

  useEffect(() => {
    // console.log("Input ref current", inputRef.current);
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus({
          preventScroll: false,
          focusVisible: true,
        });
      }, 300);
    }
  }, []);

  useEffect(() => {
    navigate(`/sonicboxes?s=${search}`);
  }, [search, navigate]);

  const handleCancel = () => {
    setSearch("");
    navigate(`/sonicboxes`);
  };

  return (
    <>
      <StickyHeader
        style={{ flexDirection: "column", padding: "0.5em" }}
        startHeight={5}
        endHeight={5}
      >
        <SearchInput
          ref={(input) => input?.focus()}
          value={search}
          onChange={setSearch}
          onCancel={handleCancel}
          autoFocus={true}
        />
        {data && search !== "" ? (
          <div
            style={{ padding: "1em", textAlign: "center", fontSize: "0.75em" }}
          >
            {data && data.length > 0 ? (
              <T name={search}>explorer.resultsfor</T>
            ) : (
              <T name={search}>explorer.searchempty</T>
            )}
          </div>
        ) : null}
      </StickyHeader>
      <ul className="locals-container">
        {search !== "" &&
          data &&
          data.map((local, index) => (
            <SingleLocal
              key={index.toString()}
              {...local}
              onClick={(nameseo) => navigate(`/sonicbox/${nameseo}`)}
              label={<T>explorer.getin</T>}
            />
          ))}
      </ul>
    </>
  );
}
