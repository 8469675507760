import React, { useEffect, useMemo, useState } from "react";
import Ticker from "react-ticker";
import "../styles/now-playing.scss";
import DefaultSongImage from "../../../../images/default-song.jpg";
import DevPre from "../../../dev/DevPre";
import FavoriteButton from "../../../FavoriteButton";
import LoadableImage from "../../../basic/LoadableImage";
import cleanSongTitle from "../../../../utils/cleanSongTitle";
import { T } from "../../../../hooks/useLanguage";
import { useRef } from "react";
import NextPlaying from "./NextPlaying";
import { useLocal } from "../../../../hooks/useApi";
import { useLocation, useNavigate } from "@reach/router";
import useTheme from "../../../../hooks/useTheme";

const NowPlaying = ({
  radioid,
  cover,
  playing,
  artist,
  playingsongid: songid,
}) => {
  const [expanded, setExpanded] = useState(false);
  const infoRef = useRef();
  const infoStaticRef = useRef();
  const infoContainerRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading, data, isError, error } = useLocal(radioid);

  const { assets } = useTheme();
  const { ChevronUp } = assets;

  const cleanedSongTitle = useMemo(
    () => cleanSongTitle(playing, artist),
    [playing, artist]
  );

  useEffect(() => {
    // console.log("useEffect location", location);
    if (location?.hash === "#playing") {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [location]);

  const toggleExpanded = () => {
    if (expanded) {
      navigate(location.pathname);
    } else {
      navigate(location.pathname + "#playing");
    }
  };

  return (
    <div className={["now-playing", expanded ? "expanded" : ""].join(" ")}>
      <p className="now-playing-label">
        <T>sonicbox.nowplaying</T>
      </p>
      <button className="btn now-playing-expand-btn" onClick={toggleExpanded}>
        <img src={ChevronUp} alt={expanded ? "Collapse" : "Expand"} />
      </button>
      <>
        <DevPre>
          {typeof cover}
          <br />
          {JSON.stringify(cover, null, " ")}
        </DevPre>
        <div className="now-playing-cover-row">
          <FavoriteButton songid={songid} />
          <LoadableImage
            src={cover !== "" ? cover : DefaultSongImage}
            alt={playing}
            className="song-cover"
          />
        </div>
        <div
          ref={infoContainerRef}
          className="now-playing-info"
          onClick={toggleExpanded}
        >
          {infoRef.current?.clientWidth >
            infoContainerRef.current?.clientWidth ||
          infoStaticRef.current?.clientWidth >
            infoContainerRef.current?.clientWidth ? (
            <Ticker speed={2} mode={"smooth"}>
              {({ index }) => (
                <div ref={infoRef} className="now-playing-info-container">
                  <h4 title={playing}>
                    {cleanedSongTitle} <small>{artist}</small>
                  </h4>
                </div>
              )}
            </Ticker>
          ) : (
            <div>
              <div
                ref={infoStaticRef}
                className="now-playing-info-container now-playing-info-container-static"
              >
                <h4 title={playing}>
                  {cleanedSongTitle} <small>{artist}</small>
                </h4>
              </div>
            </div>
          )}
        </div>
      </>
      <div className="next-playing-wrapper">
        <NextPlaying {...data} />
      </div>
    </div>
  );
};

export default NowPlaying;
