export default function SonicboxHeader({ name, radioimage }) {
  return (
    <>
      <div className="sonicbox-header-wrapper">
        <div className="sonicbox-header">
          <img className="sonicbox-header-image" src={radioimage} alt={name} />
          <h1>{name}</h1>
        </div>
      </div>
      <div className="sonicbox-header-placeholder" />
    </>
  );
}
