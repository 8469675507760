import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Loader from "./Loader";

const LoadableImage = (
  { src, alt, className = "", style = {}, altComponent = null },
  ref
) => {
  const loaded = useRef(false);
  const [loading, setLoading] = useState(true);
  const [hide, setHide] = useState(false);

  const hideIfNotLoaded = useCallback(() => {
    if (!loaded.current) {
      // console.log("image not loaded", src);
      setHide(true);
      setLoading(false);
    }
  }, [loaded, src]);

  useEffect(() => {
    if (!loaded.current) {
      setInterval(hideIfNotLoaded, 5000);
    }
  }, [src, loaded, hideIfNotLoaded]);

  useEffect(() => {
    if (typeof src === "string" && src !== "") {
      loaded.current = false;
      setLoading(true);
      setHide(false);
    } else {
      setLoading(false);
      setHide(true);
    }
  }, [src]);

  return (
    <div className={className} style={{ position: "relative", ...style }}>
      {hide && <div className="placeholder">{altComponent}</div>}
      {loading ? (
        <Loader
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        />
      ) : null}
      {src !== "" && (
        <img
          ref={ref}
          src={src}
          alt={alt}
          style={{ opacity: loading || hide ? 0.01 : 1 }}
          className="img"
          onLoad={() => {
            // console.log("on load img", src);
            loaded.current = true;
            setLoading(false);
            setHide(false);
          }}
        />
      )}
    </div>
  );
};

export default forwardRef(LoadableImage);
