import { useRef, useEffect, useState } from "react";
import { useSingleSong, usePlaylist } from "../../../../hooks/useApi";
import { T } from "../../../../hooks/useLanguage";
import Loader from "../../../basic/Loader";
import Song from "./Song";

export default function Suggested({ radio }) {
  const lastUpdateRef = useRef(0);
  const seedRef = useRef(0);
  const [seed, setSeed] = useState(Math.random());
  const [randomSongs, setRandomSongs] = useState([]);
  const { isLoading, song } = useSingleSong(
    radio?.radioid,
    radio?.playing,
    radio?.playingsongid
  );

  const playlistid = song?.playlistid;

  const { songs, isLoading: isLoadingPlaylist } = usePlaylist(
    radio?.radioid,
    playlistid,
    true,
    0,
    400
  );

  // update random song only if there are new songs
  useEffect(() => {
    if (songs?.length > 8) {
      const newSongs = [];
      let localSeed = seed;
      while (newSongs.length < 8) {
        const randomIndex = Math.floor((localSeed % 1) * songs.length);
        if (!newSongs.includes(songs[randomIndex])) {
          newSongs.push(songs[randomIndex]);
        }
        console.log(localSeed, randomIndex, songs.length, newSongs.length);
        localSeed += 0.125;
      }
      const now = new Date().getTime();
      if (now - lastUpdateRef.current > 60 * 1000 || seedRef.current !== seed) {
        lastUpdateRef.current = now;
        setRandomSongs(newSongs);
        seedRef.current = seed;
      }
    }
  }, [songs, seed]);

  return (
    <div className="suggested">
      <div className="suggested-header">
        <h2>
          <T>sonicbox.suggested</T>
        </h2>
        <button
          type="button"
          className="btn btn-refresh"
          onClick={() => setSeed(Math.random())}
        >
          <T>sonicbox.suggestmore</T>
        </button>
      </div>
      {/* <h2>
        {playlistid} {radio?.playing}
      </h2> */}
      {randomSongs.length === 0 && (isLoading || isLoadingPlaylist) ? (
        <Loader />
      ) : null}
      {/* <pre>
        <h3>
          Song ({radio?.playing}) | Playlist ({playlistid})
        </h3>
      </pre> */}
      <div className="suggested-content">
        <div className="two-columns-songs">
          {randomSongs?.map((song) => (
            <Song key={song.songid} {...song} nameseo={radio?.nameseo} />
          ))}
        </div>
      </div>
    </div>
  );
}
