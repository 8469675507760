import React from "react";
import { Router } from "@reach/router";
import { ReactQueryDevtools } from "react-query/devtools";

import Layout from "../components/layout/Layout";
import PublicRoute from "../components/PublicRoute";
import PrivateRoute from "../components/PrivateRoute";
import Login from "../components/screens/login";
import Home from "../components/screens/home";
import Seo from "../components/seo";
import Sonicboxes from "../components/screens/sonicboxes";
import Sonicbox from "../components/screens/sonicbox";
import { apiQueryClient } from "../hooks/useApi";
import {
  PROFILE_SECTION_CREDITS,
  PROFILE_SECTION_FAVORITES,
  PROFILE_SECTION_MENU,
  PROFILE_SECTION_ORDERS,
  PROFILE_SECTION_PROFILE,
  PROFILE_SECTION_SCANNER,
} from "../constants";
import Menu from "../components/screens/account/Menu";
import Orders from "../components/screens/account/Orders";
import Profile from "../components/screens/account/Profile";
import Credits from "../components/screens/account/Credits";
import Scanner from "../components/screens/account/Scanner";
import Favorites from "../components/screens/account/Favorites";
import versionUpdater from "../services/versionUpdater";
import Redeem from "../components/screens/redeem";
import Task from "../components/screens/tasks";
import { LanguageProvider } from "../hooks/useLanguage";
import Privacy from "../components/screens/privacy";

versionUpdater();

const App = () => (
  <LanguageProvider>
    <Layout>
      <Seo description="Sonicbox - " lang="es" meta={[]} title="Sonicbox" />
      <Router>
        <PublicRoute path="/" component={Home} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/redeem/:code" component={Redeem} />
        <PublicRoute path="/task/:nameseo" component={Task} />
        <PrivateRoute path="/sonicboxes" component={Sonicboxes} />
        <PrivateRoute path="/sonicbox/:nameseo" component={Sonicbox} />
        <PrivateRoute path={`/${PROFILE_SECTION_MENU}`} component={Menu} />
        <PrivateRoute
          path={`/${PROFILE_SECTION_FAVORITES}`}
          component={Favorites}
        />
        <PrivateRoute path={`/${PROFILE_SECTION_ORDERS}`} component={Orders} />
        <PrivateRoute
          path={`/${PROFILE_SECTION_CREDITS}`}
          component={Credits}
        />
        <PrivateRoute
          path={`/${PROFILE_SECTION_SCANNER}`}
          component={Scanner}
        />
        <PrivateRoute
          path={`/${PROFILE_SECTION_PROFILE}`}
          component={Profile}
        />
        <PublicRoute path="/privacy" component={Privacy} />
      </Router>
      <ReactQueryDevtools client={apiQueryClient} initialIsOpen={false} />
    </Layout>
  </LanguageProvider>
);

export default App;
