import { Link, useNavigate } from "@reach/router";
import {
  PROFILE_SECTION_PROFILE,
  PROFILE_SECTION_CREDITS,
  PROFILE_SECTION_FAVORITES,
  PROFILE_SECTION_ORDERS,
  PROFILE_SECTION_HELP,
  HELP_MODAL,
} from "../../../constants";
import { useLocal, useUser } from "../../../hooks/useApi";
import useLanguage, { LangSwitcher, T } from "../../../hooks/useLanguage";
import PointsBadge from "../../basic/PointsBadge";
import ProfileButton from "./components/ProfileButton";
import "./styles/menu.scss";
import FavoritesIcon from "../../../images/icon-heart.png";
import HelpIcon from "../../../images/icon-help.png";
import LanguageIcon from "../../../images/icon-language.png";
import AccountHeader from "./components/AccountHeader";
import Button from "../../basic/Button";
import { useModal } from "../../../hooks/useModal";

export default function Menu() {
  const { i18n } = useLanguage();
  const { nameseo } = useLocal();
  const { logout } = useUser();
  const { openModal } = useModal(HELP_MODAL);

  const handleOpenHelp = () => {
    openModal();
  };

  return (
    <div className="menu">
      <AccountHeader
        back={
          typeof nameseo === "string" && nameseo !== ""
            ? `/sonicbox/${nameseo}`
            : "/sonicboxes"
        }
      />
      <ul>
        <li>
          <Link to={`/${PROFILE_SECTION_PROFILE}`} className="menu-link">
            <ProfileButton />
          </Link>
        </li>
        <li>
          <Link to={`/${PROFILE_SECTION_CREDITS}`} className="menu-link">
            <PointsBadge />
            <T>profile.Credits</T>
          </Link>
        </li>
        <li>
          <Link to={`/${PROFILE_SECTION_FAVORITES}`} className="menu-link">
            <img
              src={FavoritesIcon}
              alt={i18n.t("profile.Favorites")}
              className="icon"
            />
            <T>profile.Favorites</T>
          </Link>
        </li>
        <li>
          <button type="button" onClick={handleOpenHelp} className="menu-link">
            <img src={HelpIcon} alt={i18n.t("profile.Help")} className="icon" />
            <T>profile.Help</T>
          </button>
        </li>
        <li>
          <div className="lang-switcher-menu-item">
            <img
              src={LanguageIcon}
              alt={i18n.t("profile.Language")}
              className="icon"
            />
            <T>profile.Language</T> <LangSwitcher />
          </div>
        </li>
        <li>
          <Button className="logout-button" onClick={logout}>
            <T>profile.Logout</T>
          </Button>
        </li>
      </ul>
    </div>
  );
}
