import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";

import { getNameseoByRadioid, useTerms, useUser } from "../../../hooks/useApi";

import { T } from "../../../hooks/useLanguage";

import DevPre from "../../dev/DevPre";
import Loader from "../../basic/Loader";
import SonicboxLogoPNG from "../../../images/sonicbox-logo.png";
import "../../../styles/index.scss";
import decryptCode from "../../../utils/decryptCode";
import FacebookButton from "../../FacebookButton";
import GoogleButton from "../../GoogleButton";
import TermsAndConditionsContent from "../../TermsAndConditionsContent";
import { useMemo } from "react";

const Redeem = ({ code }) => {
  const { user, logged, loginRedirect, setLoginRedirect } = useUser();
  const [storedCodes, setStoredCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // setIsLoading
  const [enabled, setEnabled] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const [radioNameseo, setRadioNameseo] = useState(null);

  const terms = useTerms(radioNameseo);

  const parsedCode = useMemo(() => {
    if (code === "code") {
      const paramC = new URLSearchParams(window.location.search).get("c");
      return decodeURIComponent(paramC);
    }
    return code;
  }, [code]);

  useEffect(() => {
    console.log("logged", logged, "radioNameseo", radioNameseo);
    if (!logged) {
      if (radioNameseo) {
        window.localStorage.setItem(
          "redirectLogin",
          `/sonicbox/${radioNameseo}`
        );
        setLoginRedirect(`/sonicbox/${radioNameseo}`);
      }
      setIsLoading(false);
    }
  }, [radioNameseo, logged, setLoginRedirect]);

  // console.log("isLoading", isLoading, "logged", logged);

  useEffect(() => {
    // console.log("terms", terms?.data);
    if (terms?.data && terms?.data?.status === "success") {
      setShowTerms(true);
    }
  }, [terms?.data]);

  // useEffect(() => {
  //   if (terms?.radioid) {
  //     if (enabled) {
  //       window.localStorage.setItem(`tempterms`, terms.radioid);
  //     } else {
  //       window.localStorage.removeItem(`tempterms`);
  //     }
  //   }
  // }, [terms?.radioid, enabled]);

  useEffect(() => {
    const newCodes = JSON.parse(
      window.localStorage.getItem("sonicbox-codes") || "[]"
    );
    console.log(newCodes);
    if (newCodes.indexOf(parsedCode) > -1) {
      console.log("Code already saved");
    } else {
      newCodes.push(parsedCode);
      window.localStorage.setItem("sonicbox-codes", JSON.stringify(newCodes));
    }
    setStoredCodes(newCodes);
  }, [parsedCode]);

  useEffect(() => {
    console.log("useEffect logged", logged);
    if (logged) {
      setTimeout(async () => {
        if (storedCodes.length > 0) {
          // console.log(storedCodes);
          const firstCode = decryptCode(storedCodes[0]);
          // console.log("first code decrypted", firstCode);
          const splitted = firstCode.split("-");
          // console.log(splitted);
          if (splitted.length === 2) {
            const codeRadioId = splitted[1];
            // navigate(`/sonicbox/${codeRadioId}`);
            const nameseo = await getNameseoByRadioid(codeRadioId);
            // console.log("navigate to nameseo", nameseo);
            if (nameseo) {
              navigate(`/sonicbox/${nameseo}`);
            } else {
              navigate(`/sonicboxes`);
            }
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }, 1000);
    }
  }, [parsedCode, logged, storedCodes, loginRedirect]);

  useEffect(() => {
    (async () => {
      console.log("parsedCode", parsedCode);
      if (parsedCode) {
        const decrypted = decryptCode(parsedCode);
        // console.log("decrypted code ", code, decrypted);
        const splitted = decrypted.split("-");
        if (splitted.length > 1) {
          const codeRadioId = splitted[1];
          // console.log("decrypted codeRadioId", codeRadioId);
          const nameseo = await getNameseoByRadioid(codeRadioId);
          // console.log("decrypted nameseo", nameseo);
          setRadioNameseo(nameseo);
        }
      }
    })();
  }, [parsedCode]);

  return (
    <div className="container index-page">
      <img src={SonicboxLogoPNG} alt="Sonicbox logo" className="page-logo" />
      <p>
        <T>home.welcome</T>
      </p>
      <DevPre>
        {typeof logged} - {logged ? "logged" : "not logged"}
      </DevPre>
      <h1>
        <T>home.redeemtitle</T>
      </h1>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {radioNameseo && (
            <TermsAndConditionsContent
              nameseo={radioNameseo}
              style={{ marginTop: 40, marginBottom: 40 }}
              onAccept={setEnabled}
            />
          )}
          <FacebookButton enabled={enabled || !showTerms} />
          <GoogleButton enabled={enabled || !showTerms} />
          <p>
            <Link to="/login" disabled={!enabled && showTerms}>
              <T>home.mustlogin</T>
            </Link>
          </p>
        </>
      )}
    </div>
  );
};

export default Redeem;
