import { useNavigate } from "@reach/router";
import { useCallback, useRef, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useCode, useUser } from "../hooks/useApi";
import useLanguage, { T } from "../hooks/useLanguage";
import Button from "./basic/Button";

const CODE_LENGTH = 6;
const REDEEM_URL_PATTERN = /^https?:\/\/sonicboxmusic\.com\/redeem.*/; // https:// sonicboxmusic.com/redeem/uQ4rAEA1yyg_xQ9U16Y-rQ
const REDEEM_URL_PATTERN_2 = /^https?:\/\/millsonicbox\.com\/redeem.*/;

export default function QRScanner() {
  const { i18n } = useLanguage();
  const [enterManually, setEnterManually] = useState(false);
  const [manualCode, setManualCode] = useState("");
  const { sendCode, mutation } = useCode();
  const { updateUser } = useUser();
  const sentCode = useRef();
  const data = useRef(null);
  const navigate = useNavigate();

  const handleError = (err) => {
    console.error(err);
  };

  const handleScan = useCallback(
    async (result) => {
      if (typeof result !== "undefined" && result !== null && !enterManually) {
        console.log(result?.text);
        if (sentCode.current !== result.text) {
          data.current = result.text;
          if (
            REDEEM_URL_PATTERN.test(result.text) ||
            REDEEM_URL_PATTERN_2.test(result.text)
          ) {
            const targetPath = `/redeem/${result.text.split("redeem/")[1]}`;
            navigate(targetPath);
          }
        }
      }
    },
    [enterManually, navigate]
  );

  const handleSubmitCode = useCallback(
    (e) => {
      e.preventDefault();
      if (
        manualCode.length === CODE_LENGTH &&
        sentCode.current !== manualCode
      ) {
        sentCode.current = manualCode;
        console.log("sendCode", { ccode: manualCode });
        mutation.reset();
        sendCode({ ccode: manualCode }, () => {
          console.log("trigger Callback");
          updateUser();
          setEnterManually(false);
          setManualCode("");
          navigate("/credits");
        });
      }
    },
    [manualCode, mutation, sendCode, updateUser, navigate]
  );

  return (
    <div className="qr-scanner">
      <>
        <QrReader
          delay={500}
          onError={handleError}
          onResult={handleScan}
          containerStyle={{
            width: "100%",
            height: "100%",
          }}
          constraints={{
            facingMode: "environment",
          }}
        />
        <Button
          onClick={() => setEnterManually(true)}
          className={["enter-code-btn", "brand", "bordered"].join(" ")}
        >
          <T>navbar.entercodemanually</T>
        </Button>
      </>
      {enterManually && (
        <div className="enter-code-wrapper">
          <div className="enter-code-container">
            <p>
              <T>navbar.entercodetext</T>
            </p>
            <input
              name="ccode"
              type="text"
              placeholder={i18n.t("navbar.entercode")}
              value={manualCode}
              onChange={(e) => setManualCode(e.target.value)}
            />
            <Button className="brand" onClick={handleSubmitCode}>
              <T>send</T>
            </Button>
            <Button
              className="bordered accent"
              onClick={() => setEnterManually(false)}
            >
              <T>cancel</T>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
