import { useCallback, useEffect, useState } from "react";
import { useTerms } from "../hooks/useApi";
import Loader from "./basic/Loader";
import Button from "./basic/Button";
import { T } from "../hooks/useLanguage";

import "../styles/terms.scss";

export default function TermsAndConditionsContent({
  nameseo,
  isPeeking = false,
  ticked = false,
  onAccept = () => {},
  style = {},
}) {
  const { refetch, data, isLoading, acceptTerms, radioData, form } =
    useTerms(nameseo);
  const [checked, setChecked] = useState([]);

  const handleCheck = useCallback((event) => {
    console.log(event.target.name, event.target.checked);
    if (event.target.checked) {
      setChecked((checked) => [...checked, event.target.name]);
    } else {
      setChecked((checked) =>
        checked.filter((name) => name !== event.target.name)
      );
    }
  }, []);

  useEffect(() => {
    if (form) {
      let acceptAll = true;
      form.forEach(({ input }) => {
        if (input?.required) {
          if (!checked.includes(input.name)) {
            acceptAll = false;
          }
        }
      });
      onAccept(acceptAll);
    }
  }, [checked, form, onAccept]);

  useEffect(() => {
    if (acceptTerms.isSuccess) {
      refetch();
    }
  }, [acceptTerms.isSuccess, refetch]);

  if (isLoading) return <Loader />;
  if (data) {
    return (
      <>
        {form ? (
          <div className="terms-container" style={style}>
            <h3>{radioData?.name}</h3>
            {form.map((formGroup, index) => (
              <FormGroup
                key={index}
                onChange={handleCheck}
                {...formGroup}
                isPeeking={isPeeking}
              />
            ))}
          </div>
        ) : (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: data.text }}
              className="terms-container"
              style={style}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="checkbox"
                checked={ticked}
                onChange={(event) => onAccept((ticked) => !ticked)}
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 10,
                  backgroundColor: ticked ? "green" : "red",
                }}
                disabled={!!form}
                id="terms-checkbox"
              />
              <label htmlFor="terms-checkbox">
                {ticked ? <T>accepted</T> : <T>accept</T>}
              </label>
            </div>
          </>
        )}
      </>
    );
  }
  return null;
}

function FormGroup({ label, input, small, link, onChange, isPeeking }) {
  let labelText = label?.textContent;
  const inputName = input?.name;
  const smallText = small?.textContent;
  const linkHref = link?.href;
  const linkText = link?.textContent;
  const required = !!input?.required;

  if (link && linkHref && linkText) {
    labelText = labelText.replace(linkText, "");
  }

  if (isPeeking) {
    return (
      link && (
        <div
          className="form-group"
          style={{ display: "flex", marginBottom: 10 }}
        >
          <a
            href={linkHref}
            target="_blank"
            rel="noreferrer"
            style={{ whiteSpace: "pre" }}
          >
            {linkText}
          </a>
        </div>
      )
    );
  }

  return (
    <div className="form-group" style={{ display: "flex", marginBottom: 10 }}>
      <input
        type="checkbox"
        onChange={onChange}
        name={inputName}
        required={required}
        style={{
          width: 20,
          height: 20,
          flexGrow: 0,
          flexShrink: 0,
          marginRight: 20,
          display: "flex",
          textAlign: "left",
        }}
      />
      <div style={{ textAlign: "left" }}>
        <label>
          {labelText}
          {link && (
            <a
              href={linkHref}
              target="_blank"
              rel="noreferrer"
              style={{ whiteSpace: "pre" }}
            >
              {linkText}
            </a>
          )}
        </label>{" "}
        <small style={{ opacity: 0.5 }}>{smallText}</small>
      </div>
    </div>
  );
}
