import React from "react";
import { Link, useLocation } from "@reach/router";
import "../../styles/tabbar.scss";
import PointsBadge from "../basic/PointsBadge";
import homeIcon from "../../images/icon-home.png";
import searchIcon from "../../images/icon-search.png";
import profileIcon from "../../images/icon-profile.png";
import useLanguage from "../../hooks/useLanguage";
import { useLocal } from "../../hooks/useApi";
import {
  PROFILE_SECTION_CREDITS,
  PROFILE_SECTION_FAVORITES,
  PROFILE_SECTION_MENU,
  PROFILE_SECTION_ORDERS,
  PROFILE_SECTION_PROFILE,
} from "../../constants";
import LoadableImage from "../basic/LoadableImage";

export default function TabBar() {
  const location = useLocation();
  const { i18n } = useLanguage();
  const { nameseo, data: radioData } = useLocal();

  const isExplorer = location.pathname === "/sonicboxes";
  const isSonicbox = location.pathname.indexOf("/sonicbox/") === 0;
  const isLogin = location.pathname === "/" || location.pathname === "/login";
  const isTask = location.pathname.indexOf("/task/") === 0;

  const isSearch = location.search !== "";

  const searchUrl = isExplorer
    ? isSearch
      ? "/sonicboxes"
      : "/sonicboxes?s"
    : isSonicbox && nameseo
    ? isSearch
      ? `/sonicbox/${nameseo}`
      : `/sonicbox/${nameseo}?s`
    : null;

  const isRedeem = location.pathname.indexOf("/redeem/") === 0;

  const isProfile =
    location.pathname.indexOf(`/${PROFILE_SECTION_MENU}`) === 0 ||
    location.pathname.indexOf(`/${PROFILE_SECTION_ORDERS}`) === 0 ||
    location.pathname.indexOf(`/${PROFILE_SECTION_PROFILE}`) === 0 ||
    location.pathname.indexOf(`/${PROFILE_SECTION_FAVORITES}`) === 0 ||
    location.pathname.indexOf(`/${PROFILE_SECTION_CREDITS}`) === 0;

  if (isLogin || isTask || isRedeem) {
    return null;
  }

  return (
    <>
      <div className="tab-bar">
        <Link
          to="/sonicboxes"
          className={[
            "tab-bar-tab",
            isExplorer && !isSearch ? "active" : "",
          ].join(" ")}
        >
          <img src={homeIcon} alt={i18n.t("explorer")} />
        </Link>
        {searchUrl && searchUrl !== "" ? (
          <Link
            to={searchUrl}
            className={["tab-bar-tab", isSearch ? "active" : ""].join(" ")}
          >
            <img src={searchIcon} alt={i18n.t("explorer")} />
          </Link>
        ) : nameseo !== null && nameseo !== "" ? (
          <Link
            to={`/sonicbox/${nameseo}`}
            className={["tab-bar-tab", "tab-bar-radio-tab"].join(" ")}
          >
            <LoadableImage src={radioData?.radioimage} alt={radioData?.name} />
          </Link>
        ) : null}
        {isSonicbox ? (
          <Link to="/menu" className={["tab-bar-tab"].join(" ")}>
            <PointsBadge />
          </Link>
        ) : (
          <Link
            to="/menu"
            className={["tab-bar-tab", isProfile ? "active" : ""].join(" ")}
          >
            <img src={profileIcon} alt={i18n.t("explorer")} />
          </Link>
        )}
      </div>
    </>
  );
}
